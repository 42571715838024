import { Hooks } from '@cfra-nextgen-frontend/shared';
import { fontWeights } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
    AxisLabelsFormatterContextObject,
    SeriesClickCallbackFunction,
    SeriesOptionsType,
    TooltipFormatterContextObject,
} from 'highcharts/highstock';
import { useMemo } from 'react';
import { Chart } from './CFRAChart';
import { defaultGridLineStyle, getDefaultFontStyle, getToolTipOptions } from './Options';

const defaultSeriesTheme = {
    color: '#5F9EA0',
    negativeColor: '#d12b10',
    borderRadiusTopLeft: '50%',
    borderRadiusTopRight: '50%',
};

const defaultChartMargins = {
    spacingLeft: 0,
    spacingRight: 20,
    spacingBottom: 15,
    marginTop: 5,
};

const defaultPlotOptions = {
    enabled: true,
    overflow: 'allow',
    format: '{point.y:.2f}%',
    crop: false,
    style: {
        ...getDefaultFontStyle(),
        fontWeight: fontWeights.Regular,
        textAlign: 'right',
    },
};

function defaultXAxisFormatter(this: Highcharts.AxisLabelsFormatterContextObject) {
    return `<div title="${String(
        this.value,
    )}" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${String(this.value)}</div>`;
}

const defaultYAxisLabelFormatter = {
    format: '{value}%',
    style: getDefaultFontStyle(),
    overflow: 'allow',
};

export function BarChartWithBigLabels({
    categories,
    categoriesData,
    setChartRef,
    chartRef,
    columnHeaderFormatter,
    tooltipFormatter,
    onBarClick,
    theme,
    xAxisLabelsFormatter = defaultXAxisFormatter,
    customYAxisLabelProps,
}: {
    categories: Array<string>;
    categoriesData: Array<Number | null>;
    setChartRef: (value: any) => void;
    chartRef?: React.RefObject<HighchartsReact.RefObject>;
    columnHeaderFormatter: (item: any, key: any) => string;
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    onBarClick?: SeriesClickCallbackFunction;
    theme?: any;
    xAxisLabelsFormatter?: (this: AxisLabelsFormatterContextObject) => string;
    customYAxisLabelProps?: any;
}) {
    const chartWidth = Hooks.useHighchartsWidth(chartRef);
    const defaultChartWidth = 600;

    const series = [
        {
            type: 'bar',
            ...(theme ? theme.seriesStyle : defaultSeriesTheme),
            data: categoriesData,
            pointPlacement: 0,
        },
    ];

    const isAssetClassCategory = categoriesData.length === 6;

    const [xAxisLabelWidth, xAxisOffset] = useMemo(() => {
        if (isAssetClassCategory) {
            const assetClassCategoryOffset = 220;
            const xAxisLabelWidth = Math.min(assetClassCategoryOffset - 20, chartWidth * 0.45);
            return [xAxisLabelWidth, assetClassCategoryOffset];
        }
        const maxAxisOffset = 300;
        const xAxisOffset = Math.min(maxAxisOffset, Math.floor((chartWidth || defaultChartWidth) * 0.5));
        const xAxisLabelWidth = Math.min(xAxisOffset - 20, chartWidth * 0.45);
        return [xAxisLabelWidth, xAxisOffset];
    }, [chartWidth, isAssetClassCategory]);

    const [yAxisMinPadding, yAxisMaxPadding] = useMemo(() => {
        const barChartWidth = (chartWidth || defaultChartWidth) - xAxisOffset;
        const minPaddingInPx = 60;
        const maxPaddingInPx = 60;
        const yAxisMinPadding = +(minPaddingInPx / barChartWidth).toFixed(2);
        const yAxisMaxPadding = +(maxPaddingInPx / barChartWidth).toFixed(2);
        return [yAxisMinPadding, yAxisMaxPadding];
    }, [chartWidth, xAxisOffset]);

    const options: Highcharts.Options = {
        chart: {
            height: isAssetClassCategory ? 360 : 240,
            ...(theme ? theme.chartMargins : defaultChartMargins),
        },
        exporting: {
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
            },
            enabled: false,
            fallbackToExportServer: false,
            allowHTML: true,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    ...(theme ? theme.plotLabelsOptions : defaultPlotOptions),
                },
                ...(theme
                    ? theme.plotStyling
                    : {
                          minPointLength: 2,
                          pointWidth: 16,
                      }),
                events: {
                    click: onBarClick,
                },
            },
        },
        series: series as Array<SeriesOptionsType>,
        title: {
            text: undefined,
        },
        tooltip: {
            ...getToolTipOptions(true, tooltipFormatter),
            outside: true,
            style: { filter: 'drop-shadow(0px 0px 3.68px rgba(0, 0, 0, 0.3))' },
        },
        xAxis: {
            categories: categories,
            labels: {
                align: 'left',
                enabled: true,
                style: {
                    whiteSpace: 'normal',
                },
                formatter: xAxisLabelsFormatter,
                useHTML: true,
                x: 0,
                y: 6,
            },
            offset: theme ? theme.XAxisOffet.offset : xAxisOffset,
            lineWidth: 0,
            title: {
                text: undefined,
            },
            ...(theme ? theme.xAxisGridStyle : {}),
        },
        yAxis: {
            title: {
                text: undefined,
            },
            visible: true,
            labels: customYAxisLabelProps ? customYAxisLabelProps : defaultYAxisLabelFormatter,
            ...(theme ? theme.yAxisGridStyle : defaultGridLineStyle),
            maxPadding: yAxisMaxPadding,
            minPadding: yAxisMinPadding,
        } as Highcharts.YAxisOptions,
    };

    return <Chart options={options} setChartRef={setChartRef} enableExportMenusAndTitle={false} theme={theme} />;
}
