import { Typography } from '@cfra-nextgen-frontend/shared/src/components/Typography';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { styled } from '@mui/material';

export const TypographyStyle1 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '15px',
    color: '#002B5A',
}));

export const TypographyStyle2 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    color: '#002B5A',
    letterSpacing: '0.1%',
}));

export const TypographyStyle3 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
    color: '#3c3c3c',
    letterSpacing: '0.1%',
}));

export const TypographyStyle4 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13px',
    color: '#609EA0',
}));

export const TypographyStyle5 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '12px',
    color: '#007AB9',
}));

export const TypographyStyle6 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '15px',
    color: '#002B5A',
}));

export const TypographyStyle7 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#3c3c3c',
}));

export const TypographyStyle8 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '11px',
    color: '#808080',
}));

export const TypographyStyle9 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13px',
    color: '#5A5A5A',
}));

export const TypographyStyle10 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#007AB9',
}));

export const TypographyStyle11 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#555555',
}));

export const TypographyStyle12 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '13.5px',
    color: '#007AB9',
}));


export const TypographyStyle13 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    fontWeight: 500,
    color: '#002B5A',
    letterSpacing: '0.1%',
}));

export const TypographyStyle14 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontWeight: 400,
    fontSize: '14px',
    color: '#3c3c3c',
    letterSpacing: '0.1%',
}));

export const TypographyStyle15 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#808080',
}));

export const TypographyStyle16 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '32px',
    color: '#DDDDDD',
}));

export const TypographyStyle17 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#007BB8',
}));

export const TypographyStyle18 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikLight,
    fontSize: '13px',
    color: '#808080',
}));

export const TypographyStyle19 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    color: '#3C3C3C',
    lineHeight: '28px',
    fontWeight: 400
}));

// Standard Small Gray Font
export const TypographyStyle20 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '12px',
    color: '#3C3C3C',
}));

// Standard Medium Blue Font
export const TypographyStyle21 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '14px',
    color: '#002B5A',
}));