import { RefObject, useEffect, useMemo, useState } from 'react';

export function useIsVisible(ref: RefObject<HTMLElement[]>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

        if (ref.current) {
            for (var curr of ref.current) {
                observer.observe(curr);
            }
        }
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
}

export function useIsElementVisible(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [])


    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [observer, ref])

    return isIntersecting
}