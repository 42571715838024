import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { ButtonProps } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { createTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const chipRootStyle = {
    backgroundColor: '#E5E9EE',
    borderRadius: '100px',
    height: '24px',
    display: 'flex',
    margin: '4px 6px',
};

const chipLabelStyle = {
    boxShadow: 'none',
    padding: '7px 10px',
    fontSize: '12px',
    fontFamily: fontFamilies.GraphikRegular,
    color: '#0B2958',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const ScreenerChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    ...chipRootStyle,
                },
                label: {
                    ...chipLabelStyle,
                },
            },
        },
    },
});

const chipRootStyleV2 = {
    backgroundColor: '#F3F5FC',
    borderRadius: '100px',
    height: '32px',
    display: 'flex',
    padding: '10px 5px',
    marginBottom: '7px',
    marginRight: '9px',
};

const chipLabelStyleV2 = {
    boxShadow: 'none',
    padding: '7px 10px',
    fontSize: '13px',
    fontFamily: fontFamilies.GraphikRegular,
    color: '#007AB9',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const ScreenerChipThemeV2 = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#007AB9 !important',
                    fontSize: '17px !important',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    ...chipRootStyleV2,
                },
                label: {
                    ...chipLabelStyleV2,
                },
            },
        },
    },
});

export const BaseLabel = styled(Paper)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: '2',
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxShadow: 'none',
}));

export const Label = styled(BaseLabel)(({ theme }) => ({
    color: '#57626A',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.4px',
    display: 'block',
    width: '100%',
    marginTop: '14px',
    paddingRight: '19px',
}));

export const MainLabel = styled(Label)(({ theme }) => ({
    marginTop: '0px',
    padding: '15px 29px',
}));

type TransparentButtonChipProps = ButtonProps & { disableiconrightmargin?: string };

export const TransparentButtonChip = styled(ETFLinkButton)<TransparentButtonChipProps>(
    ({ theme, disableiconrightmargin }) => ({
        '& .MuiButton-startIcon': { marginLeft: 0, ...(disableiconrightmargin && { marginRight: 0 }) },
        ':hover': { backgroundColor: 'rgba(0, 123, 184, 0.10)' },
        backgroundColor: '#FFF',
        color: '#3078B5',
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: '14px',
        letterSpacing: '0.46px',
        lineHeight: '22px',
        textAlign: 'right',
        padding: '7px 5px',
        borderRadius: '4px',
        fontWeight: 400,
    }),
);
