import { MaterialIcons } from '@cfra-nextgen-frontend/shared/src';
import { CFRAMuiIconWrapper } from '@cfra-nextgen-frontend/shared/src/components/Icon/SvgIcon';
import { Theme } from '@mui/material';

export function WatchListIcon({ outline, customIconTheme }: { outline?: boolean; customIconTheme?: Theme }) {
    return (
        <CFRAMuiIconWrapper
            MuiIcon={outline ? MaterialIcons.StarOutline : MaterialIcons.Star}
            customIconTheme={customIconTheme}
        />
    );
}
