import { Dispatch, createContext, useMemo, useState } from 'react';
import { FiltersData } from '../../Form/types/filters';

type FiltersModalState = {
    openFiltersModal: boolean;
    setOpenFiltersModal: Dispatch<boolean>;
    filtersPostData: Record<string, any> | undefined;
    setFiltersPostData: Dispatch<React.SetStateAction<FiltersModalState['filtersPostData']>>;
    filtersMetadata: FiltersData | undefined;
    setFiltersMetadata: Dispatch<FiltersData | undefined>;
    activeIndex: Record<string, number>;
    setActiveIndex: Dispatch<Record<string, number>>;
    searchTerm: string;
    setSearchTerm: Dispatch<string>;
};

export const FiltersModalContext = createContext<FiltersModalState>({} as FiltersModalState);

export function FiltersModalContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [openFiltersModal, setOpenFiltersModal] = useState(false);
    const [filtersPostData, setFiltersPostData] = useState<Object | undefined>(undefined);
    const [filtersMetadata, setFiltersMetadata] = useState<FiltersData | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [activeIndex, setActiveIndex] = useState({});

    return (
        <FiltersModalContext.Provider
            value={{
                openFiltersModal,
                setOpenFiltersModal,
                filtersPostData,
                setFiltersPostData,
                filtersMetadata,
                setFiltersMetadata,
                activeIndex,
                setActiveIndex,
                searchTerm,
                setSearchTerm,
            }}>
            {memorizedChildren}
        </FiltersModalContext.Provider>
    );
}
