import { CFRAChips, MaterialIcons } from '@cfra-nextgen-frontend/shared/';
import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@emotion/react';
import { Box, Theme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FiltersModalContext } from './FiltersModalContext';
import { ResultsContext } from './ResultsContext';
import { Label, MainLabel, ScreenerChipTheme, TransparentButtonChip } from './ResultsPanelRowStyle';
import { ChipItem, OnChipDeleteClick } from './types';

export const getResultsCount = (count: number | undefined): any => {
    if (count === -1) {
        return 'Loading...';
    }
    return (count ? count.toString() : 'No') + ' results found';
};

const getClearChip = (clearAll: () => void) => {
    return (
        <TransparentButtonChip
            key='Clear All'
            text='Clear'
            sx={{ margin: '4px 8px', padding: '4px 5px' }}
            startIcon={<MaterialIcons.Autorenew style={{ fontSize: '18px', color: '#3078B5' }} />}
            onClick={clearAll}
        />
    );
};

const ChipComponentList: React.FC<{
    chipItems: Record<string, ChipItem>;
    onChipDeleteClick: OnChipDeleteClick;
    theme?: Theme;
}> = ({ chipItems, onChipDeleteClick, theme }) => {
    const { setOpenFiltersModal, setActiveIndex } = useContext(FiltersModalContext);

    if (Object.keys(chipItems).length === 0) {
        return null;
    }

    function handleOnChipClick(filterKey: string) {
        const activeIndexByKey: { [key: string]: any } = {};
        const { filterSections } = chipItems[filterKey].stateData || {};

        if (filterSections) {
            Object.keys(filterSections).forEach((key) => {
                const { orderIndex, parent = 'root' } = filterSections[key];
                activeIndexByKey[parent] = orderIndex;
            });

            setActiveIndex({ ...activeIndexByKey });
            setOpenFiltersModal(true);
        }
    }

    const chipList = Object.entries(chipItems).map(([key, chipValue]) => {
        const { label, additionalLabels, values } = chipValue.chip || {};
        let chipLabel = `${additionalLabels || ''}${label || ''}${values ? `: ${values}` : ''}`;

        return {
            chipKey: key,
            chipLabel,
            chipProps: {
                title: chipLabel,
                deleteIcon: <MaterialIcons.Clear style={{ fontSize: '13px', color: '#0B2958' }} />,
                onDelete: onChipDeleteClick(chipItems, key),
                onClick: () => handleOnChipClick(key),
            },
        };
    });

    if (theme) {
        return (
            <ThemeProvider theme={theme}>
                <CFRAChips key='AllChips' chipItems={chipList} />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider key='ChipsTheme' theme={ScreenerChipTheme}>
            <CFRAChips key='AllChips' chipItems={chipList} />
        </ThemeProvider>
    );
};

export function ScreenerFiltersChipPanel({
    showClearButton = true,
    passedTheme,
    externalChipItems,
    externalOnChipDeleteClick,
}: {
    showClearButton?: boolean;
    passedTheme?: Theme;
    externalChipItems?: Record<string, ChipItem>;
    externalOnChipDeleteClick?: (key: string) => void;
}) {
    const {
        chipStateManager: {
            chipState: { chipItems: internalChipItems, resultCount },
        },
        chipEventsManager: { onChipClearAllClick, onChipDeleteClick: internalOnChipDeleteClick },
    } = useContext(ResultsContext);

    const [chipItems, setChipItems] = useState<Record<string, ChipItem>>({});

    useEffect(() => {
        // need to keep order of chips if have internalChipItems and externalChipItems
        setChipItems((previousChipItems) => {
            // remove old items from previousChipItems
            for (const key in previousChipItems) {
                if (!internalChipItems?.hasOwnProperty(key) && !externalChipItems?.hasOwnProperty(key)) {
                    delete previousChipItems[key];
                }
            }

            for (const key in internalChipItems || {}) {
                previousChipItems[key] = internalChipItems?.[key]!;
            }

            for (const key in externalChipItems || {}) {
                previousChipItems[key] = externalChipItems?.[key]!;
            }

            return { ...previousChipItems };
        });
    }, [internalChipItems, externalChipItems]);

    const onChipDeleteClick: OnChipDeleteClick = useCallback(
        (_, key) => {
            if (externalChipItems && !externalOnChipDeleteClick) {
                throw new Error('externalOnChipDeleteClick is required when externalChipItems is provided');
            }

            if (externalOnChipDeleteClick && key in (externalChipItems || {})) {
                return () => {
                    externalOnChipDeleteClick(key);
                };
            }

            return internalOnChipDeleteClick(internalChipItems || {}, key);
        },
        [externalOnChipDeleteClick, externalChipItems, internalOnChipDeleteClick, internalChipItems],
    );

    let resultWidth = '135px';

    const theme = passedTheme || ScreenerChipTheme;

    if (passedTheme) {
        resultWidth = '0';
    }

    return (
        <Grid container sx={{ paddingLeft: '29px', paddingRight: '15px', maxHeight: '30%' }}>
            {showClearButton && (
                <Grid item sx={{ width: resultWidth }}>
                    <Label>{getResultsCount(resultCount)}</Label>
                </Grid>
            )}
            <Grid
                item
                sx={{
                    width: `calc(100% - ${resultWidth})`,
                    maxHeight: '100%',
                    overflow: 'auto',
                    ...scrollbarThemeV2,
                }}>
                <Stack direction='row' flexWrap='wrap' display='flex' maxWidth={'100%'} alignItems='center'>
                    {chipItems && Object.keys(chipItems).length > 0 && (
                        <>
                            <ChipComponentList
                                chipItems={chipItems}
                                onChipDeleteClick={onChipDeleteClick}
                                theme={theme}
                            />
                            {showClearButton && (
                                <Box display='flex' sx={{ ml: 'auto' }}>
                                    {getClearChip(onChipClearAllClick)}
                                </Box>
                            )}
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}

export function ScreenerResultsCountPanel() {
    const {
        chipStateManager: {
            chipState: { resultCount },
        },
    } = useContext(ResultsContext);

    return <MainLabel>{getResultsCount(resultCount)}</MainLabel>;
}

export function ScreenerResultsChipComponents() {
    const {
        chipStateManager: {
            chipState: { chipItems },
        },
        chipEventsManager: { onChipDeleteClick, onChipClearAllClick },
    } = useContext(ResultsContext);

    if (!chipItems) {
        return {
            chipList: [],
            clearButton: <></>,
        };
    }

    return {
        chipList: <ChipComponentList chipItems={chipItems} onChipDeleteClick={onChipDeleteClick} />,
        clearButton: getClearChip(onChipClearAllClick),
    };
}
