import { getDownloadAction } from '@cfra-nextgen-frontend/shared/src/analytics/utils';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard, AgGirdExportButton } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import {
    AgGridSelectedRowsContext,
    AgGridSelectedRowsContextProvider,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { PageWithBreadcrumbsInHeaderContext } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeaderContext';
import { WatchListColumn } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import { getSearch2Results } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { AnalyticsTickerSearchContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { FiltersModalOpenButton } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalOpenButton';
import { ScreenerResultsChipComponents } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { MainLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SelectedWatchlistState } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { SearchInput } from '@cfra-nextgen-frontend/shared/src/components/Screener/tickerSearch/SearchInput';
import { IMetadataFields, ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import {
    ssrmMaxRowsToFetch,
    watchListColumnWidth,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import {
    determineGetDataSource,
    determineGetSsrDataExportFn,
    getRowID,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { AgGridExportProps } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { Box, Grid, Stack, SxProps, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { exportAgGrid, exportSSRAgGrid } from 'components/excelExport/export';
import { ScreenerViews } from 'features/etfScreener/components/ScreenerViews';
import { SelectedWatchlistContainer } from 'features/etfScreener/components/watchLists/SelectedWatchlist';
import { UpdateWatchlistButton } from 'features/etfScreener/components/watchLists/UpdateWatchlist';
import { WatchListModal, WatchListModalOpenButton } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/WatchListModals';
import { WatchListsMenu } from 'features/etfScreener/components/watchLists/WatchListsMenu';
import { SnackbarProvider } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { getScreenerData, getScreenerDataSSR, getScreenerReqBody } from '../api/screener';
import { AddSymbolModal, AddSymbolModalButton } from './AddSymbolModal';
import { CustomViewEditorModalOpenButton } from './CustomViewEditorModals';
import { SaveScreenButton } from './FiltersModal';
import { SavedScreenMenu } from './SavedScreensMenu';
import { SelectedScreenContainer } from './SelectedSaveScreen';
import { UpdateScreenButton } from './UpdateSavedScreen';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';

type ScreenerCardProps = {
    cardName: string;
};

const topRightActionPanelStyles: SxProps = { alignItems: 'start' };

function FiltersAndScreenBlock({ cardName }: { cardName: string }) {
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);

    const {
        saveScreenState: { selectedScreen, screenAction },
    } = useContext(SaveScreenContext);

    const chipComponents = ScreenerResultsChipComponents();

    const showChips = chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0;
    const showSaveScreenBtn = showChips && typeof selectedScreen?.id !== 'number';
    const showUpdateScreenBtn = typeof selectedScreen?.id === 'number' && screenAction !== 'FETCH_SCREEN';
    const boxStyles = { display: 'flex', mx: '6px', alignItems: 'center' };

    return (
        <SnackbarProvider maxSnack={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0px' }}>
                <Stack
                    direction='row'
                    flexWrap='wrap'
                    display='flex'
                    maxWidth={'100%'}
                    alignItems='center'
                    sx={{ marginBottom: '8px' }}>
                    <div key='ScreenerFilterButtonDiv' style={{ margin: '0px 18px 4px 0' }}>
                        <FiltersModalOpenButton cardName={cardName} manageFiltersButtonText='Screen ETFs' />
                    </div>
                    <>
                        <Box sx={boxStyles}>
                            <SelectedScreenContainer key='selectedScreen' />
                            <SelectedWatchlistContainer key='selectedWatchlist' />
                        </Box>
                        {showChips && chipComponents.chipList}
                        <Box sx={boxStyles}>
                            {showChips && chipComponents.clearButton}
                            {showSaveScreenBtn && <SaveScreenButton cardName={cardName} />}
                            {showUpdateScreenBtn && <UpdateScreenButton cardName={cardName} />}
                        </Box>
                    </>
                </Stack>
            </Box>
        </SnackbarProvider>
    );
}

function ResultsAndSearchRow({ cardName }: { cardName: string }) {
    const { selectedRowIds, getUnselectedRowsId } = useContext(AgGridSelectedRowsContext);
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);
    const {
        watchListState: { selectedWatchlist, selectedWatchlistState },
    } = useContext(WatchListContext);
    const isWatchlistApplied =
        typeof selectedWatchlist?.id === 'number' && selectedWatchlistState === SelectedWatchlistState.SELECTED;

    const showWatchlistActionBtn = chipState.resultCount > 0 && (isWatchlistApplied || selectedRowIds.length > 0);
    const [showSymbolModal, setShowSymbolModal] = useState(false);

    const getMessage = (count: number): string => {
        if (count === -1) {
            return 'Loading...';
        } else if (count > 0 && isWatchlistApplied) {
            const selectedRowCount = count - (getUnselectedRowsId()?.length || 0);
            return `${selectedRowCount} out of ${count} Results selected`;
        } else if (count > 0 && selectedRowIds.length) {
            return `${selectedRowIds.length} out of ${count} Results selected`;
        } else {
            return (count ? count.toString() : 'No') + ' results found';
        }
    };

    return (
        <Grid container justifyContent='space-between' alignItems='center' direction='row'>
            {showWatchlistActionBtn && (
                <Grid item pl={'29px'}>
                    {typeof selectedWatchlist?.id !== 'number' && <WatchListModalOpenButton />}
                    {isWatchlistApplied && (
                        <UpdateWatchlistButton cardName={cardName} disabled={getUnselectedRowsId()?.length === 0} />
                    )}
                </Grid>
            )}
            <Grid item>
                <MainLabel>{getMessage(chipState.resultCount)}</MainLabel>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item>
                {isWatchlistApplied && (
                    <>
                        <AddSymbolModal
                            title='Add Symbol'
                            showModal={showSymbolModal}
                            closeModal={() => setShowSymbolModal(false)}
                            selectedWatchlist={selectedWatchlist}></AddSymbolModal>
                        <AddSymbolModalButton onClick={() => setShowSymbolModal(true)} />
                    </>
                )}
            </Grid>
            <Grid item>
                <SearchInput cardName={cardName} placeholder='Search by ticker or name' />
            </Grid>
        </Grid>
    );
}

const ssrDataExportFn = determineGetSsrDataExportFn(getScreenerDataSSR, 'etf_data');
const getDataSource = determineGetDataSource(getScreenerDataSSR, 'etf_data');

const maxNumberOfColumnsToExport = 25;

function SetScreenerExportButton({
    SSRDataExportFn,
    gridRef,
    cardName,
    fieldMetadata
}: {
    SSRDataExportFn: ReturnType<typeof ssrDataExportFn>;
    gridRef: React.RefObject<AgGridReact>;
    cardName: string;
    fieldMetadata?: IMetadataFields[]
}) {
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);

    const {
        screenerViewState: { screenerActiveView },
    } = useContext(ScreenerViewContext);
    const { setRightSideSlotContent } = useContext(PageWithBreadcrumbsInHeaderContext);
    const { customViewEditorStateDispatcher } = useContext(CustomViewEditorContext);

    const isMoreThan25CustomColumnsSelected = useMemo(
        () => screenerActiveView?.fieldsData && screenerActiveView.fieldsData.length > maxNumberOfColumnsToExport,
        [screenerActiveView?.fieldsData],
    );
    const getExportCallback = useCallback(
        (agGridExportProps: AgGridExportProps) => () => {
            if (isMoreThan25CustomColumnsSelected) {
                customViewEditorStateDispatcher({
                    type: ['OpenEditActiveViewConfirmationModal'],
                });
                return;
            }
            exportSSRAgGrid(gridRef, SSRDataExportFn, agGridExportProps, fieldMetadata);
        },
        [SSRDataExportFn, gridRef, isMoreThan25CustomColumnsSelected, customViewEditorStateDispatcher, fieldMetadata],
    );
    const getAnalyticsCallback = useCallback(
        (agGridExportProps: AgGridExportProps) => (type: string) =>
            registerAction({
                action: isMoreThan25CustomColumnsSelected
                    ? `attempt to export more than ${maxNumberOfColumnsToExport} columns`
                    : getDownloadAction(type),
                cardName: cardName,
                reportName: agGridExportProps.fileName,
                reportType: type,
            }),
        [cardName, isMoreThan25CustomColumnsSelected, registerAction],
    );

    useEffect(
        () =>
            setRightSideSlotContent(
                <Box sx={{ paddingTop: '42px', paddingRight: '28px' }}>
                    <AgGirdExportButton
                        label={cardName}
                        excelExportAsOfDateField='fund_nav_data.as_of_date'
                        getExportCallback={getExportCallback}
                        getAnalyticsCallback={getAnalyticsCallback}
                        exportAgGrid={exportAgGrid}
                        exportSSRAgGrid={exportSSRAgGrid}
                        getFormattedValuesFirst
                        fieldMetadata={fieldMetadata}
                    />
                </Box>,
            ),
        [SSRDataExportFn, cardName, setRightSideSlotContent, gridRef, getAnalyticsCallback, getExportCallback, fieldMetadata],
    );

    return null;
}

export function ScreenerCard({ cardName }: ScreenerCardProps) {
    const {
        screenerViewState: { screenerActiveView },
    } = useContext(ScreenerViewContext);
    if (!screenerActiveView) {
        throw new Error('screenerActiveView is not set.');
    }

    const { filtersPostData, searchTerm } = useContext(FiltersModalContext);
    const {
        chipStateManager: { chipStateDispatcher },
    } = useContext(ResultsContext);

    const isBelow600px = useMediaQuery('(max-width:600px)');

    const screenerRequestBody = useMemo(
        () => getScreenerReqBody(filtersPostData, screenerActiveView.fieldsData),
        [filtersPostData, screenerActiveView.fieldsData],
    );

    const getScreenerDataInputProps = useMemo(
        () => ({
            requestBody: screenerRequestBody,
            view: screenerActiveView.key,
            from: 0,
            size: ssrmMaxRowsToFetch,
            search: searchTerm,
        }),
        [screenerActiveView.key, screenerRequestBody, searchTerm],
    );
    const screenerData = getScreenerData(getScreenerDataInputProps) as UseQueryResult<ScreenerEtfData>;

    const { tickerSearchActionProps, setTickerSearchActionProps } = useContext(AnalyticsTickerSearchContext);

    useEffect(() => {
        if (tickerSearchActionProps && !screenerData.isLoading) {
            globalThis.analytics?.registerAction?.({
                ...tickerSearchActionProps,
                search2Results: getSearch2Results(
                    tickerSearchActionProps.search2Term || '',
                    screenerData.data?.results.total || 0,
                ),
            });
            setTickerSearchActionProps(undefined);
        }
    }, [tickerSearchActionProps, screenerData.data?.results, screenerData.isLoading, setTickerSearchActionProps]);

    useEffect(() => {
        if (!chipStateDispatcher) {
            return;
        }

        if (screenerData.isLoading) {
            chipStateDispatcher({ type: 'SetResultCount', newState: { resultCount: -1 } });
            return;
        }

        chipStateDispatcher({
            type: 'SetResultCount',
            newState: {
                resultCount: screenerData.data?.results.total || 0,
            },
        });
    }, [screenerData.isLoading, screenerData.data, screenerData.data?.results.etf_data, chipStateDispatcher]);

    const screenerViewTabs = useMemo(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    width: 'calc(100% - 2 * 30px)',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    alignItems: 'center',
                    borderBottom: '1px solid #E4E5E9',
                }}>
                <Box sx={{ display: 'flex', overflowX: 'auto' }}>
                    <ScreenerViews />
                </Box>
                <Box sx={{ marginLeft: '20px' }}>
                    <CustomViewEditorModalOpenButton cardName={cardName} />
                </Box>
            </Box>
        ),
        [cardName],
    );
    const resultsAndSearchRow = useMemo(() => <ResultsAndSearchRow cardName={cardName} />, [cardName]);

    const topLeftActionPanelItems = useMemo(
        () => [<FiltersAndScreenBlock cardName={cardName} key='topLeftActionPanelItems' />],
        [cardName],
    );
    const topRightActionPanelItems = useMemo(
        () => [
            <Box sx={{ display: 'flex' }} key='topRightActionPanelItems'>
                <Grid container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end' }} columnSpacing={3.125}>
                    <Grid item>
                        <WatchListsMenu key='watchListMenu' hideButtonText={isBelow600px} />
                    </Grid>
                    <Grid item>
                        <SavedScreenMenu key='savedScreenMenu' hideButtonText={isBelow600px} />
                    </Grid>
                </Grid>
            </Box>,
        ],
        [isBelow600px],
    );

    const isNoInformationAvailable = useMemo(
        () =>
            !screenerData.data ||
            !screenerData.data._metadata ||
            !screenerData.data._metadata.fields ||
            screenerData.data._metadata.fields.length === 0 ||
            !screenerData.data._viewdata ||
            !screenerData.data._viewdata.fields ||
            screenerData.data._viewdata.fields.length === 0 ||
            !screenerData.data.results ||
            !screenerData.data.results.etf_data ||
            screenerData.data.results.etf_data.length === 0,
        [screenerData.data],
    );

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (isNoInformationAvailable || !screenerData.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({
            screenerData: screenerData.data,
            cardName
        });
        result.columnDefs.unshift(WatchListColumn);
        return result;
    }, [cardName, isNoInformationAvailable, screenerData.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const SSRDataExportFn = useMemo(
        () =>
            ssrDataExportFn({
                metadataFields: screenerData?.data?._metadata.fields || [],
                requestParams: {
                    view: screenerActiveView.key,
                    requestBody: screenerRequestBody,
                    search: searchTerm,
                },
            }),
        [screenerData?.data?._metadata.fields, screenerActiveView.key, screenerRequestBody, searchTerm],
    );

    const gridRef = useRef<AgGridReact>(null);

    const dataSource = useMemo(
        () =>
            getDataSource({
                metadataFields: screenerData?.data?._metadata.fields || [],
                etfData: screenerData?.data?.results.etf_data || [],
                requestParams: {
                    requestBody: screenerRequestBody,
                    view: screenerActiveView.key,
                    search: searchTerm,
                },
            }),
        [
            screenerData?.data?._metadata.fields,
            screenerData?.data?.results.etf_data,
            screenerRequestBody,
            screenerActiveView.key,
            searchTerm,
        ],
    );

    return (
        <>
            <SetScreenerExportButton cardName={cardName} gridRef={gridRef} SSRDataExportFn={SSRDataExportFn}
                fieldMetadata={screenerData.data?._metadata.fields} />
            <AgGridSelectedRowsContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <WatchListModal cardName='Watch List' savedItemType={UserSavedItemsLookupID.Watchlist} />
                    <AgGirdCard
                        ref={gridRef}
                        columnDefs={columnDefs}
                        useSSRMode={true}
                        SSRDataSource={dataSource}
                        SSRrowsToFetch={ssrmMaxRowsToFetch}
                        rowMultiSelectWithClick={true}
                        rowSelection='multiple'
                        suppressRowClickSelection={true}
                        getRowID={getRowID}
                        customFlexibleColumns={customFlexibleColumns}
                        getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                        topRightActionPanelStyles={topRightActionPanelStyles}
                        topLeftActionPanelItems={topLeftActionPanelItems}
                        topRightActionPanelItems={topRightActionPanelItems}
                        topPanelSlot2Content={screenerViewTabs}
                        topPanelSlot3Content={resultsAndSearchRow}
                        labelPanelContainerStyles={{ paddingTop: '36px' }}
                    />
                </SnackbarProvider>
            </AgGridSelectedRowsContextProvider>
        </>
    );
}
