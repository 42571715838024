import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

export function setCustomFlexWidths(isBelowLg: boolean, customFlexibleColumns: Array<string>) {
    return (gridRef: React.RefObject<AgGridReact>) => {
        if (!gridRef.current) return;
        const getWidth = (minWidth: number, maxWidth: number, allColumnsNames: Array<string>) => {
            if (isBelowLg) minWidth = 170;
            const columnsIntersection = allColumnsNames.filter((x) => customFlexibleColumns.includes(x));
            let width = isBelowLg
                ? maxWidth -
                  (breakpointsTheme.breakpoints.values['lg'] - globalThis.window.innerWidth) /
                      columnsIntersection.length
                : maxWidth;

            if (width < minWidth) return minWidth;
            if (width > maxWidth) return maxWidth;
            return width;
        };
        const columnDefs = gridRef.current?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefs) return;
        const allColumnsNames: Array<string> = [];
        columnDefs.forEach((element) => {
            if (element.headerName) {
                allColumnsNames.push(element.headerName);
            }
        });
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => {
                if (
                    !(
                        columnDef.headerName &&
                        columnDef.minWidth &&
                        columnDef.maxWidth &&
                        allColumnsNames.length > 0 &&
                        customFlexibleColumns.includes(columnDef.headerName)
                    )
                ) {
                    return columnDef;
                }

                return {
                    ...columnDef,
                    width: getWidth(columnDef.minWidth, columnDef.maxWidth, allColumnsNames),
                };
            }),
        );
    };
}

export function Link(props: { value: string; handleOpen?: () => void }) {
    // use eslint-disable-next-line to avoid the warning - The href attribute is required for an anchor to be keyboard accessible.
    // we don't need href here, use <a>, but not <button> to same ag grid ellipsis behavior
    return (
        // eslint-disable-next-line
        <a
            onClick={props.handleOpen}
            style={{
                color: '#007bb8',
                cursor: 'pointer',
            }}>
            {props.value}
        </a>
    );
}

export function agGridGetRenderedRowsCount(grid: AgGridReact) {
    return grid.api.getRenderedNodes().length;
}

export function agGridGetAllRowsCount(grid: AgGridReact) {
    return grid.api.getModel().getRowCount();
}