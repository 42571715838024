import {
    API_URL,
    ENVIRONMENT_NAME,
    OPENSEARCH_URL,
    USER_MANAGEMENT_URL,
} from '@cfra-nextgen-frontend/shared/src/config';
import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
    determineUseMultipleData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames, Environments } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { axiosAuth } from './api-common';
import { determineSendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';

export const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.Fundynamix]: {
            instance: axiosAuth(API_URL, { showQueries: ENVIRONMENT_NAME !== Environments.Production }),
            instanceType: ApiInstanceTypes.Axios,
        },
        [ApiNames.OpenSearch]: {
            instance: axiosAuth(OPENSEARCH_URL),
            instanceType: ApiInstanceTypes.Axios,
        },
        [ApiNames.UserManagement]: {
            instance: axiosAuth(USER_MANAGEMENT_URL),
            instanceType: ApiInstanceTypes.Axios,
        },
    },
});

export const UseMultipleData = determineUseMultipleData(getData);
export const UseData = determineUseData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
export const sendSingleRequest = determineSendSingleRequest({ UseData });
