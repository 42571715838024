import { useLocation, useRoutes } from 'react-router-dom';
// Pattern with child routes
import { HomeRoutes } from 'features/home';
// Pattern with no child routes
import { Fallback } from '@cfra-nextgen-frontend/shared';
import { ENABLE_CFRA_INSIGHTS, ENABLE_ETF_SCREENER } from '@cfra-nextgen-frontend/shared/src/config';
import { Analytics } from 'analytics/analytics';
import { ErrorBoundary } from '@cfra-nextgen-frontend/shared/src/components/Fallbacks/ErrorBoundary';
import { getUserDetails } from 'features/auth/api/userDetails';
import { CFRAInsightsRoutes } from 'features/cfraInsights/routes/CFRAInsightsRoutes';
import { ETF } from 'features/etfData';
import { ScreenerRoutes } from 'features/etfScreener/routes/ScreenerRoutes';
import { MarketTrendsRoutes } from 'features/marketTrends/routes/MarketTrendsRoutes';
import { TopNavigation } from 'features/topNavigation';
import { useEffect } from 'react';
import { AppRoutePaths } from 'utils/enums';
import { isLoggedIn, useAuth } from '../lib/authentication';
import { ProjectSpecificResourcesContextProvider } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { sendSingleRequest } from 'utils/api';

export default function AppRoutes() {
    const commonRoutes = [
        { path: AppRoutePaths.Home, element: <HomeRoutes /> },
        { path: `${AppRoutePaths.EtfDetails}/ticker/:ticker/exchange/:exchange`, element: <ETF /> },
        { path: `${AppRoutePaths.EtfDetails}/cfra-id/:cfraId`, element: <ETF /> },
        { path: `${AppRoutePaths.MarketTrends}/*`, element: <MarketTrendsRoutes /> },
    ];

    if (ENABLE_ETF_SCREENER) {
        commonRoutes.push({ path: `${AppRoutePaths.Screener}/*`, element: <ScreenerRoutes /> });
    }

    if (ENABLE_CFRA_INSIGHTS) {
        commonRoutes.push({ path: `${AppRoutePaths.CfraInsights}/*`, element: <CFRAInsightsRoutes /> });
    }

    const element = useRoutes([...commonRoutes]);
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if (user) isLoggedIn();
    }, [location, user]);

    useEffect(() => {
        globalThis.analytics = new Analytics();
    }, []);

    useEffect(() => {
        cfraData.userData = {
            name: user?.username || 'unauthorized',
        };
    }, [user?.username]);

    const userDetails = getUserDetails();

    useEffect(() => {
        userDetails.then((response) => {
            globalThis.analytics?.fillUserDetails?.(response);
        });
    }, [userDetails]);

    if (!user) return null;

    return (
        <>
            <TopNavigation />
            {/* Error Boundary Ensures We never lose the Top Navigation if an error is thrown */}
            <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                <ProjectSpecificResourcesContextProvider projectSpecificResources={{ sendSingleRequest }}>
                    {element}
                </ProjectSpecificResourcesContextProvider>
            </ErrorBoundary>
        </>
    );
}
