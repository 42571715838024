import { ETFInfoButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFInfoButton';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { SxProps, Theme } from '@mui/material';
import { useCallback } from 'react';

type Props = {
    analyticsOpenModalCallback?: () => void;
    buttonFontSize?: number;
    description: string | React.ReactNode;
    descriptionStyle?: SxProps;
    modalBoxStyles?: SxProps;
    title: string;
    titleStyle?: SxProps;
    tooltipText?: string;
    tooltipTheme?: Theme;
    zIndex?: number;
};

export function ETFInfo({
    analyticsOpenModalCallback,
    buttonFontSize,
    description,
    descriptionStyle,
    modalBoxStyles,
    title,
    titleStyle,
    tooltipText,
    tooltipTheme,
    zIndex,
}: Props) {
    const getOpenModalButton = useCallback(
        (handleOpen: () => void) => {
            return <ETFInfoButton onClick={handleOpen} buttonFontSize={buttonFontSize} tooltipText={tooltipText} tooltipTheme={tooltipTheme}/>;
        },
        [buttonFontSize, tooltipText, tooltipTheme],
    );

    return (
        <ETFModal
            getOpenComponent={getOpenModalButton}
            title={title}
            titleStyle={titleStyle}
            description={description}
            analyticsOpenModalCallback={analyticsOpenModalCallback}
            modalBoxStyles={{
                display: 'block',
                maxWidth: '1400px',
                maxHeight: 'none',
                overflowY: 'hidden',
                ...modalBoxStyles,
            }}
            zIndex={zIndex}
            descriptionStyle={descriptionStyle}
        />
    );
}
