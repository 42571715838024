import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { createTheme } from '@mui/material';

const commonButtonsTheme = createTheme(createTheme(), {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {},
            },
        },
    },
});

const commonRoundedButtonTheme = createTheme(commonButtonsTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#FFF',
                    fontFamily: fontFamilies.GraphikMedium,
                    backgroundColor: '#007BB8',
                    borderColor: '#007BB8',
                    letterSpacing: '1.5px',
                    '&:hover': {
                        backgroundColor: '#002B5A',
                        borderColor: '#002B5A',
                    },
                },
            },
        },
    },
});

export const roundedTextButtonThemeV1 = createTheme(commonRoundedButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '25px',
                    width: '80%',
                    textTransform: 'lowercase',
                    fontSize: '15px',
                },
            },
        },
    },
});

export const roundedTextButtonThemeV2 = createTheme(commonRoundedButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    color: '#3078B5',
                    backgroundColor: '#FFF',
                    border: '1px solid #007BB8',
                },
                root: {
                    '&:hover': {
                        color: '#3078B5',
                        backgroundColor: '#E5E9EF',
                        border: '1px solid #007BB8',
                    },
                    color: '#FFF',
                    backgroundColor: '#007BB8',
                    textTransform: 'none',
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '14px',
                    letterSpacing: '0.46px',
                    lineHeight: '22px',
                    borderRadius: '10px',
                    padding: '0px',
                    width: '155px',
                    minWidth: 'unset',
                    height: '36px',
                    '&:disabled': {
                        backgroundColor: '#9E9E9E',
                        color: '#FFF',
                    },
                },
            },
        },
    },
});

export const roundedTextButtonThemeV3 = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#F3F5FC',
                    fontFamily: fontFamilies.GraphikRegular,
                    backgroundColor: '#002B5A',
                    borderColor: '#002B5A',
                    '&:hover': {
                        backgroundColor: '#3078B5',
                        borderColor: '#3078B5',
                    },
                    borderRadius: '8px',
                    fontSize: '13.5px',
                    lineHeight: '36px',
                    fontWeight: fontWeights.Medium,
                    textTransform: 'none'
                },
            },
        },
    },
});

export const roundedTextButtonThemeV2BorderRadius4 = createTheme(roundedTextButtonThemeV2, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#00527A',
                        border: '1px solid #00527A',
                    },
                    borderRadius: '4px',
                    fontFamily: fontFamilies.GraphikRegular,
                },
            },
        },
    },
});

export const tuneIconButtonTextTheme = createTheme(commonRoundedButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    fontSize: '11px',
                    marginBottom: '12px',
                },
            },
        },
    },
});

export const plusIconTextButtonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#00527A',
                        color: '#fff',
                    },
                    '&:hover svg path': {
                        fill: '#fff',
                    },
                    color: '#007BB8',
                    borderRadius: '4px',
                    backgroundColor: '#E5E9EF',
                    padding: '4px 15px',
                    width: '138px',
                    minWidth: 'unset',
                    height: '36px',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: '18px',
                    height: '18px',
                    paddingRight: '2px',
                    marginLeft: '-3.5px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '14px',
                    letterSpacing: '0.46px',
                    lineHeight: '22px',
                    width: 'inherit',
                },
            },
        },
    },
});
