import FormVirtualizeAutocomplete from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';
import { SxProps, Theme } from '@mui/material';

type ScreenerFormVirtualizeAutocompleteProps = {
    defaultInputLabel?: string;
    inputSxProps?: SxProps;
    theme?: Theme;
    ignoreFocus?: boolean;
    placeholder?: string;
} & FilterProps;

export const ScreenerFormVirtualizeAutocomplete: React.FC<ScreenerFormVirtualizeAutocompleteProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    defaultInputLabel,
    hide,
    component,
    parentSectionKey,
    onChangeClearHandler,
    theme,
    ignoreFocus,
    placeholder,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const stackLabelWithContainer = filterMetadata.sections?.[parentSectionKey]?.stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const placeholderText = placeholder;
    const themeToUse = theme || undefined;

    const filter = (
        <FormVirtualizeAutocomplete
            label={label}
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.Autocomplete,
                filterMetadataKey,
            })}
            options={filtersData.data[filterMetadataKey].items}
            placeholder={placeholderText}
            defaultValues={filterMetadata.default_values}
            submitHandler={submitHandler}
            defaultInputLabel={defaultInputLabel}
            onChangeClearHandler={onChangeClearHandler}
            theme={themeToUse}
            ignoreFocus={ignoreFocus}
            alwaysShowPlaceholder={true}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
