import { ReactComponent as ThreeDotsIcon } from '../../assets/images/ThreeDotsIcon.svg';
import { ETFIconButton } from '../../components/ETFButton';
import { ButtonProps } from './types';

export function ETFDownloadButton({ ...props }: ButtonProps) {
    return (
        <ETFIconButton
            Icon={ThreeDotsIcon}
            tooltipText={props.tooltipText || 'Download'}
            buttonFontSize={30}
            className={'data-cy-download-button'}
            {...props}
        />
    );
}
