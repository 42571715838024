import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { getEtfFilterByIdQuery } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SelectedWatchlistState } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { useContext, useEffect } from 'react';
import { SelectedSavedItemPanel } from '../SelectedSaveScreen';
import { getSavedItemsByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';

type SelectedWatchlistProps = {
    id: number;
    name: string;
};

export const emptyWatchlistValue = ['empty_placeholder']; //passing a non-existing id instead of passing empty array to avoid validation error

export function SelectedWatchlistContainer() {
    const {
        watchListState: { selectedWatchlist },
    } = useContext(WatchListContext);

    if (selectedWatchlist === undefined) {
        return <></>;
    }

    return <SelectedWatchlist id={selectedWatchlist.id} name={selectedWatchlist.name} />;
}

function SelectedWatchlist(props: SelectedWatchlistProps) {
    const { id, name } = props;

    const {
        watchListState: { selectedWatchlist, selectedWatchlistState },
        watchListDispatcher,
    } = useContext(WatchListContext);
    const { filtersPostData, searchTerm, setSearchTerm, setFiltersPostData } = useContext(FiltersModalContext);

    const {
        chipStateManager: {
            chipState: { chipItems },
            chipStateDispatcher,
        },
    } = useContext(ResultsContext);

    const { gridRef, selectAllRows } = useContext(AgGridSelectedRowsContext);
    const isFilterApplied = chipItems && Object.keys(chipItems).length > 0;

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }
    const getWatchlistByIdQry = sendSingleRequest<any>({ savedItems: id }, getSavedItemsByIdConfig);

    useEffect(() => {
        //clear watchlist if filter is applied
        if (isFilterApplied) {
            watchListDispatcher({ type: 'ClearSelectedWatchlist' });
        }
    }, [isFilterApplied, watchListDispatcher]);
    useEffect(() => {
        //select all watchlist items by default
        if (gridRef && selectedWatchlistState === SelectedWatchlistState.SELECTED && !isFilterApplied) {
            selectAllRows(selectedWatchlist?.value?.values?.map((v: { id: any }) => v.id) || []);
        }
    }, [selectedWatchlistState, gridRef, selectAllRows, selectedWatchlist, isFilterApplied, searchTerm]);

    useEffect(() => {
        //fetch selected watchlist data
        async function fetchWatchlistData() {
            const { data, isRefetchError, isRefetching } = await getWatchlistByIdQry.refetch();
            if (!isRefetching && !isRefetchError && data?.data) {
                watchListDispatcher({ type: 'SetSelectedWatchlistDetails', payload: data?.data });
            }
        }
        if (selectedWatchlistState === SelectedWatchlistState.LOADING) {
            fetchWatchlistData();
        }
    }, [selectedWatchlistState, selectedWatchlist, getWatchlistByIdQry, watchListDispatcher]);

    useEffect(() => {
        if (selectedWatchlist?.value) {
            let values = selectedWatchlist?.value?.values?.map((v: { id: any }) => v.id) || [];

            //added this to avoid validation error, when passing empty filter
            if (values.length === 0) {
                values = emptyWatchlistValue;
            }

            const selectedWatchlistFilter = getEtfFilterByIdQuery(values);

            if (!isFilterApplied && JSON.stringify(filtersPostData) !== JSON.stringify(selectedWatchlistFilter)) {
                setFiltersPostData(selectedWatchlistFilter);
            }
        }
    }, [selectedWatchlist, setFiltersPostData, filtersPostData, isFilterApplied]);

    function handleRemoveClick() {
        setSearchTerm('');
        chipStateDispatcher({ type: 'ClearChips' });
        watchListDispatcher({ type: 'ClearSelectedWatchlist' });
        //clearing watchlist selecttion state set using the 'useNavigate' hook in CreateWatchList & UpdateWatchList
        //When we create a new watchlist or add to an existing one from a Non-Screener page, and then navigate to screener via the toast message's "View" link, screener displays the respective watchlist.
        //Watchlist is removed via "Remove" link, and screener shows the default screener. However on refreshing the page, screener again loads the previous new/updated watchlist when it should load default screener.
        //This line fixes the above bug.
        window.history.replaceState(
            { ...window.history.state, usr: { setSelectedWatchlist: undefined } },
            document.title,
        );
    }

    return <SelectedSavedItemPanel name={name} loading={false} onRemoveClick={handleRemoveClick} />;
}
