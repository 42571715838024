import { FormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Form/FormDateRangePicker';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { keyToDateRangePickerLabelValue } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { Dayjs } from 'dayjs';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';
import { Theme } from '@mui/material';

type ScreenerFormDateRangePickerProps = {
    styleVariant?: string;
    buttonsText?: {
        start?: string;
        end?: string;
    };
    theme?: Theme;
} & FilterProps;

export const ScreenerFormDateRangePicker: React.FC<ScreenerFormDateRangePickerProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    getValues,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    theme,
    buttonsText,
}) => {
    const filterMetadata = filtersData.filter_metadata?.[filterMetadataKey];

    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = filterMetadata?.options
        ? [
              ...filterMetadata.options.map((option) => ({
                  label: option.label || keyToDateRangePickerLabelValue[option.key]?.label,
                  getValue: keyToDateRangePickerLabelValue[option.key]?.getValue,
              })),
              { label: 'Reset', getValue: () => [null, null] },
          ]
        : [{ label: 'Reset', getValue: () => [null, null] }];

    const stackLabelWithContainer = filterMetadata?.sections?.[parentSectionKey]?.stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    const filter = (
        <FormDateRangePicker
            name={combineIntoFormElementName({
                componentName: Components.DateRangePicker,
                filterMetadataKey,
            })}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
            shortcutsItems={shortcutsItems}
            theme={theme}
            buttonsText={buttonsText}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
