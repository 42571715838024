import { useSnackbar } from 'notistack';

import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useCallback, useContext, useEffect } from 'react';
import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { UseQueryResult } from 'react-query';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { useNavigate } from 'react-router-dom';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';

export function FundynamixWatchListSnack({
    watchlistName,
    enableWatchlist,
    watchListResult,
    externalViewWatchlist,
    message,
}: {
    watchlistName: string;
    enableWatchlist: boolean;
    watchListResult: UseQueryResult<any>;
    externalViewWatchlist?: boolean;
    message: string;
}) {
    const {
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const { watchListDispatcher } = useContext(WatchListContext);
    const { setSearchTerm } = useContext(FiltersModalContext);
    const { clearRowsSelections } = useContext(AgGridSelectedRowsContext);

    const navigate = useNavigate();
    const navigateToScreener: (data: Object) => void = useCallback(
        (data) =>
            navigate('/screener', {
                state: {
                    setSelectedWatchlist: data,
                },
            }),
        [navigate],
    );

    useEffect(() => {
        if (watchListResult.isSuccess && watchlistName && enableWatchlist) {
            let snackbarKey: string | number;

            snackbarKey = ShowSnack(
                SnackMessageForm({
                    message: message,
                    actionBtnText: 'View Watchlist',
                    onActionBtnClick: () => {
                        if (watchListResult?.data?.data) {
                            onChipClearAllClick();

                            if (saveScreenActionDispatcher !== undefined)
                                saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });

                            if (setSearchTerm !== undefined) setSearchTerm('');

                            watchListDispatcher({
                                type: 'SetSelectedWatchlist',
                                payload: watchListResult?.data?.data,
                            });

                            if (snackbarKey) {
                                closeSnackbar(snackbarKey);
                            }

                            if (externalViewWatchlist) {
                                navigateToScreener(watchListResult?.data?.data);
                            }
                        }
                    },
                }),
            );
            clearRowsSelections();
            watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
        }
    }, [
        watchListResult,
        message,
        clearRowsSelections,
        watchListDispatcher,
        watchlistName,
        ShowSnack,
        enableWatchlist,
        onChipClearAllClick,
        saveScreenActionDispatcher,
        setSearchTerm,
        closeSnackbar,
        navigateToScreener,
        externalViewWatchlist,
    ]);

    return null;
}

export function InstitutionalWatchListSnack({
    watchlistName,
    message,
    watchListResult,
    enableWatchlist,
    actionButton
}: {
    watchlistName: string;
    message: string;
    watchListResult: UseQueryResult<any>;
    enableWatchlist: boolean;
    actionButton?: {
        text: string,
        clickAction: (data: any) => void
    }
}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const { watchListDispatcher } = useContext(WatchListContext);

    useEffect(() => {
        if (watchListResult.isSuccess && watchlistName && enableWatchlist) {
            const snackbarKey = ShowSnack(
                SnackMessageForm({
                    message: message,
                    actionBtnText: actionButton?.text,
                    onActionBtnClick: () => {
                        if (snackbarKey) {
                            closeSnackbar(snackbarKey);
                        }
                        actionButton?.clickAction?.(watchListResult.data.data);
                    },
                }),
            );
            watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
        }
    }, [watchListResult, watchListDispatcher, closeSnackbar, message, watchlistName, ShowSnack, enableWatchlist]);

    return null;
}
