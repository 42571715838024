import { Layout } from '@cfra-nextgen-frontend/shared';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    DataPointsDisplayNames,
    FormatValueParams,
    SectionsNames,
    UserSavedItemsLookupID,
    fontFamilies,
    formatValue,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNameToFormattingType,
    getDataPointsDisplayNames,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { createTheme, styled, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { EtfDetailsData } from '../types/research';
import { AsteriskItemAsOf, ItemAsOf } from './shared';
import { WatchListModalOpenButton, WatchListModal } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/WatchListModals';
import { SnackbarProvider } from 'notistack';
import { WatchListContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { getUserWatchListData } from '../api/etfDetailsData';

export default function ETFDetailsData({ etfDetailsData }: { etfDetailsData: EtfDetailsData }) {
    const theme = createTheme(breakpointsTheme);

    const RightSideGrid = styled(Layout.Grid)(() => ({
        width: 'auto',
        paddingTop: '6px',
        [theme.breakpoints.down('lg')]: {
            paddingTop: '22px',
        },
    }));

    const LeftSideGrid = styled(Layout.Grid)(() => ({
        paddingTop: '6px',
        width: 'auto',
        display: 'inline-block',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    }));

    // create prefix to show + if positive price change
    const priceChangePrefix = etfDetailsData.close_price_split_dividend_adjusted_diff > 0 ? '+' : '';
    // create styled element for right side table headers
    const ItemLabel = styled(Paper)(() => ({
        fontSize: '14px',
        color: '#59626A',
        fontFamily: fontFamilies.GraphikRegular,
        boxShadow: 'none',
        lineHeight: '1',
    }));
    // create styled element for right side table cells
    const ItemValue = styled(Paper)(() => ({
        fontSize: '16px',
        color: '#59626A',
        fontFamily: fontFamilies.GraphikRegular,
        boxShadow: 'none',
        lineHeight: '1',
        [theme.breakpoints.up('sm')]: {
            whiteSpace: 'nowrap',
        },
        overflow: 'hidden',
    }));
    // create styled element for price change row
    const ItemPriceChange = styled(Paper)(() => ({
        fontSize: '18px',
        color: etfDetailsData.close_price_split_dividend_adjusted_diff > 0 ? '#5F9EA0' : '#E64A45',
        fontFamily: fontFamilies.GraphikMedium,
        boxShadow: 'none',
        //padding: '0px 0px 16px 16px',
    }));
    // create styled element for price
    const ItemPrice = styled(Paper)(() => ({
        paddingRight: '5px',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '20px',
        color: '#59626A',
        boxShadow: 'none',
        lineHeight: '1',
    }));
    // create styled element for currency
    const ItemCurrency = styled(Paper)(() => ({
        paddingRight: '5px',
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: '13px',
        color: '#59626A',
        boxShadow: 'none',
        lineHeight: '1',
    }));
    // create styled element for ticker and company name
    const ItemName = styled(Paper)(() => ({
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '26px',
        color: '#0B2958',
        boxShadow: 'none',
        lineHeight: '1',
    }));

    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName();
    // list of data points in the table on the right half of business card
    const rightPanelDataPoints: Array<DataPointsDisplayNames> = [
        DataPointsDisplayNames.AssetClass,
        DataPointsDisplayNames.NetAssets,
        DataPointsDisplayNames.ExpenseRatio,
        DataPointsDisplayNames.OneMonthReturn,
        DataPointsDisplayNames.YTDReturn,
    ];
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType(
        SectionsNames.ETFDetailHeader,
    );
    // create instance of class FormatValueParams
    let formatValueParams = new FormatValueParams({
        source: etfDetailsData,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });
    // create function for creating input parameters for formatValue function using data point display name
    function createFormatValueParameters(key: DataPointsDisplayNames) {
        return formatValueParams.create({ key: key });
    }

    const headerCells: any = [];
    const bodyCells: any = [];

    function applyNonBreakingSpaces(value: DataPointsDisplayNames) {
        const nameToNonBreakingName: Partial<Record<DataPointsDisplayNames, string>> = {
            [DataPointsDisplayNames.OneMonthReturn]: '1\u00a0Month Return',
        };
        if (!nameToNonBreakingName.hasOwnProperty(value)) return value;
        return nameToNonBreakingName[value];
    }

    rightPanelDataPoints.forEach((key, index) => {
        let displayLabel = applyNonBreakingSpaces(key);
        if (key === DataPointsDisplayNames.NetAssets) {
            displayLabel = `${key}*`;
        }

        // fill list with table header elements with values
        headerCells.push(
            <>
                <TableCell
                    sx={{
                        '&:not(:last-child)': {
                            paddingRight: '48px',
                        },
                        padding: '0px',
                        border: '0px',
                        paddingBottom: '16px',
                        overflowX: 'hidden',
                    }}>
                    <ItemLabel>{displayLabel}</ItemLabel>
                </TableCell>
            </>,
        );
        // fill list with table body elements with values
        bodyCells.push(
            <>
                <TableCell
                    sx={{
                        '&:not(:last-child)': {
                            paddingRight: '48px',
                        },
                        padding: '0px',
                        border: '0px',
                        overflowX: 'hidden',
                    }}>
                    <ItemValue>{formatValue(createFormatValueParameters(key))}</ItemValue>
                </TableCell>
            </>,
        );
    });
    // create price change element object
    const priceChange = (
        <ItemPriceChange>
            {priceChangePrefix}
            {formatValue(createFormatValueParameters(DataPointsDisplayNames.PriceChange))} ({priceChangePrefix}
            {formatValue(createFormatValueParameters(DataPointsDisplayNames.PricePercentagesChange))})
        </ItemPriceChange>
    );

    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            <Layout.ETFHeaderContainer
                id='etf-ticker-header'
                sx={{ zIndex: 1100, position: isScreenDisplay ? 'sticky' : 'static', top: '72px' }}>
                <Layout.Grid item xs={12} sx={{ paddingBottom: '6px' }}></Layout.Grid>
                <Layout.Grid item xs={12} sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <ItemName sx={{ textTransform: 'uppercase', display: 'inline-block' }}>
                        {formatValue(createFormatValueParameters(DataPointsDisplayNames.Ticker))}
                    </ItemName>
                    <ItemName sx={{ display: 'inline-block' }}> &nbsp;|&nbsp; </ItemName>
                    <ItemName sx={{ display: 'inline-block' }}>
                        {formatValue(createFormatValueParameters(DataPointsDisplayNames.Name))}
                    </ItemName>
                    <ItemName
                        sx={{
                            display: 'inline-block',
                            float: 'right',
                        }}>
                        <WatchListComponent
                            cfra_id={etfDetailsData.cfra_id}
                            ticker={etfDetailsData.ticker}
                            composite_name={etfDetailsData.composite_name}
                        />
                    </ItemName>
                </Layout.Grid>
            </Layout.ETFHeaderContainer>
            <Layout.ETFHeaderContainer>
                <Layout.Grid
                    container
                    sx={{
                        maxHeight: '300px',
                        justifyContent: 'space-between',
                    }}>
                    <LeftSideGrid item container>
                        <Layout.Grid item sx={{ display: 'inline-block' }}>
                            <ItemPrice>
                                {formatValue(createFormatValueParameters(DataPointsDisplayNames.Price))}
                            </ItemPrice>
                        </Layout.Grid>
                        <Layout.Grid item sx={{ display: 'inline-block' }}>
                            <ItemCurrency>
                                {formatValue(createFormatValueParameters(DataPointsDisplayNames.CurrencyCode))}
                            </ItemCurrency>
                        </Layout.Grid>
                        <Layout.Grid item sx={{ display: 'inline-block' }}>
                            {formatValue(createFormatValueParameters(DataPointsDisplayNames.PriceChange)) === '-' //  not show price change if it is not present in responce from API
                                ? null
                                : priceChange}
                        </Layout.Grid>
                        <Layout.Grid item>
                            <ItemAsOf>
                                {`As of ${formatValue(
                                    createFormatValueParameters(DataPointsDisplayNames.PublishablePriceAsOfDate),
                                )}`.replaceAll(' ', '\u00a0')}
                            </ItemAsOf>
                        </Layout.Grid>
                    </LeftSideGrid>
                    <RightSideGrid item container sx={{ overflow: 'auto' }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>{React.Children.toArray(headerCells)}</TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>{React.Children.toArray(bodyCells)}</TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <AsteriskItemAsOf
                            sx={{
                                paddingTop: '5px',
                            }}>
                            {`*As of ${formatValue(
                                createFormatValueParameters(DataPointsDisplayNames.PublishableNavAsOfDate),
                            )}`.replaceAll(' ', '\u00a0')}
                        </AsteriskItemAsOf>
                    </RightSideGrid>
                </Layout.Grid>
            </Layout.ETFHeaderContainer>
        </>
    );
}

function WatchListComponent({
    ticker,
    cfra_id,
    composite_name,
}: {
    ticker: string;
    cfra_id: string;
    composite_name: string;
}) {
    const watchListQuery = getUserWatchListData();
    let outlineIcon = true;

    if (watchListQuery.isSuccess && watchListQuery && watchListQuery.data) {
        outlineIcon = !watchListQuery.data.data
            .map((item: any) => item.value.values || item.value.data)
            .flatMap((item: any) => item)
            .some((item: any) => item.composite_ticker === ticker);
    }

    return (
        <WatchListContextProvider>
            <ResultsContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <WatchListModal
                        cardName='Watch List'
                        externalViewWatchlist
                        onFinishCallback={() => {
                            watchListQuery.refetch();
                        }}
                        watchListData={[
                            {
                                id: cfra_id,
                                composite_name: composite_name,
                                composite_ticker: ticker,
                                type: 'ETF',
                            },
                        ]}
                        savedItemType={UserSavedItemsLookupID.Watchlist}
                    />
                </SnackbarProvider>
            </ResultsContextProvider>
            <WatchListModalOpenButton outlineIcon={outlineIcon} />
        </WatchListContextProvider>
    );
}
