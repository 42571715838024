import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useContext, useState } from 'react';
import { SaveModal } from './SaveModal';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { getSavedItemsByTypeConfig, updateSavedItemByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { SaveModalFormProps } from './SaveModalForm';

type RenameSavedItemModalProps = {
    id: number;
    name: string;
    savedItemType: UserSavedItemsLookupID;
    modalTitle?: string;
    confirmBtnText?: string;
    cancelBtnText?: string;
    saveType?: SaveModalFormProps['saveType'];
    onCancel: () => void;
    onConfirm: () => void;
};

const defaultModalTitle = 'Rename Saved Item';
const defaultConfirmBtnText = 'Confirm';
const defaultCancelBtnText = 'Cancel';

export function RenameSavedItemModal(props: RenameSavedItemModalProps) {
    const {
        id,
        name,
        savedItemType,
        modalTitle = defaultModalTitle,
        confirmBtnText = defaultConfirmBtnText,
        cancelBtnText = defaultCancelBtnText,
        saveType = 'Save screen',
        onCancel = () => {},
        onConfirm = () => {},
    } = props;

    const [updatedName, setUpdatedName] = useState(undefined);
    const [wasFormDirty, setWasFormDirty] = useState(false);
    const [showExitConfirm, setShowExitConfirm] = useState(false);

    const analyticsCardName = `${modalTitle} close confirmation popup`;

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getSavedItemsQuery = sendSingleRequest<SavedItemListResponseTypes>({ types: [savedItemType] }, getSavedItemsByTypeConfig);

    const savedItemNameList = getSavedItemsQuery.data?.data?.map((item: { name: any }) => item?.name) || [];

    const updateSavedItemQry = sendSingleRequest<any>({ savedItems: id, config: {
        enabled: updatedName !== undefined,
        cacheTime: 3000
    }, requestBody: { name: updatedName } }, updateSavedItemByIdConfig);

    const isItemNameUpdated = Boolean(
        updatedName && updateSavedItemQry && !updateSavedItemQry.isLoading && updateSavedItemQry.data?.data?.id,
    );

    if (isItemNameUpdated) {
        onConfirm();
    }

    function handleOnModalClose() {
        if (wasFormDirty) {
            setShowExitConfirm(true);
        } else {
            onCancel();
        }
    }

    return (
        <>
            <SaveModal
                title={modalTitle}
                value={name}
                openModal={typeof id === 'number'}
                callbackOnClose={handleOnModalClose}
                saveType={saveType}
                onPositiveAction={(data: any) => setUpdatedName(data)}
                existingNames={savedItemNameList}
                positiveBtnText='Save'
                onIsDirtyChange={(v) => v && setWasFormDirty(v)}
            />
            <ConfirmationModal
                openModal={showExitConfirm}
                modalText='Exit without saving?'
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelBtnText}`,
                        cardName: modalTitle,
                    });
                    setShowExitConfirm(false);
                }}
                cancelButtonText={cancelBtnText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmBtnText}`,
                        cardName: analyticsCardName,
                    });
                    setShowExitConfirm(false);
                    onCancel();
                }}
                confirmButtonText={confirmBtnText}
            />
        </>
    );
}
