import {
    DataItem,
    IViewDataFields,
    Metadata,
    Viewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    getBooleanStringValue,
    isUserEnabledFormatter,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { getValueByPath, getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { joinWithHtmlBr, quote } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { isNumber } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import { Workbook } from 'exceljs';
import _ from 'lodash';
import {
    Components,
    CustomValidationTypes,
    CustomValueFormatterTypes,
    DisplayFormatterTypes,
    FiltersData,
    SingleColumnValidationConfig,
    Validations,
} from '../types/filters';

export function getSliderValidator(min: number, max: number, setErrorMessage?: (result: string) => void) {
    if (min >= max) {
        throw new Error(`Pointed invalid min or max values for slider validator: min: ${min}, max: ${max}`);
    }

    const validatorFunction = (value: any): string | boolean => {
        const minIsNumber = isNumber(String(value[0]));
        const maxIsNumber = isNumber(String(value[1]));

        let errorMessage = '';

        function errorMin() {
            if (errorMessage.indexOf('min ') === -1) {
                errorMessage += 'min ';
            }
        }

        function errorMax() {
            if (errorMessage.indexOf('max ') === -1) {
                errorMessage += 'max ';
            }
        }

        if (!minIsNumber) {
            errorMin();
        }

        if (!maxIsNumber) {
            errorMax();
        }

        if (parseFloat(value[0]) < min || parseFloat(value[0]) > max) {
            errorMin();
        }

        if (parseFloat(value[1]) > max || parseFloat(value[0]) < min) {
            errorMax();
        }

        if (parseFloat(value[0]) > parseFloat(value[1])) {
            errorMin();
            errorMax();
        }

        setErrorMessage?.(errorMessage);

        return errorMessage || true;
    };

    return validatorFunction;
}

export const hookFormValidationKeys: string[] = ['required', 'maxLength'];
export const hookFormCustomValidation: Record<string, any> = {
    allow_duplicates: validateDuplicate,
    email: validateEmail,
};

function validateDuplicate(fieldName: string, existingList: string[], ignoreList: string[], errorMessage: string) {
    const message = errorMessage || `${fieldName} already exists.`;

    return {
        duplicate: (fieldValue: string) => {
            if (ignoreList.map((str) => str?.toLocaleLowerCase()).includes(fieldValue.toLocaleLowerCase())) {
                return true;
            }

            return (
                !existingList.map((str) => str?.toLocaleLowerCase()).includes(fieldValue.toLocaleLowerCase()) || message
            );
        },
    };
}

function validateEmail(errorMessage: string) {
    const emailPattern = /\S+@\S+\.\S+/;
    const message = errorMessage || `Email is invalid.`;
    return {
        pattern: {
            value: emailPattern,
            message,
        },
    };
}

export function getHookFormValidationRules(
    filtersData: FiltersData,
    filterMetadataKey: string,
    parentSectionKey: string,
) {
    let validationRules: any = {};
    let customValidation: any = {};
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const { validations } = filterMetadata;

    const combinedValidations: Validations = {
        ...validations?.default,
        ...validations?.sections?.[parentSectionKey],
    };

    if (!validations || typeof validations !== 'object') {
        return validationRules;
    }

    const customValidationKeys = Object.keys(hookFormCustomValidation);

    for (const [validationKey, validationValue] of Object.entries(combinedValidations)) {
        if (hookFormValidationKeys.includes(validationKey)) {
            validationRules[validationKey] = validationValue;
        } else if (customValidationKeys.includes(validationKey)) {
            let customValidationRule = {};

            // handle custom validations
            if (validationKey === CustomValidationTypes.AllowDuplicates && validationValue?.value === false) {
                let options: string[] = [];
                if (filterMetadata.component === Components.AutoCompleteFreeSolo) {
                    options = filtersData.data?.[filterMetadataKey].items?.map((o) => o.value);
                }
                const label = filterMetadata.label || filterMetadata.item_metadata.label;
                customValidationRule = hookFormCustomValidation[validationKey](
                    label,
                    options,
                    [filterMetadata.default_value],
                    validationValue?.message,
                );
            } else if (validationKey === CustomValidationTypes.Email && validationValue?.value === true) {
                validationRules = {
                    ...validationRules,
                    ...hookFormCustomValidation[validationKey](validationValue?.message),
                };
            }

            customValidation = {
                ...customValidation,
                ...customValidationRule,
            };
        }
    }

    if (!_.isEmpty(customValidation)) {
        validationRules['validate'] = customValidation;
    }

    return validationRules;
}

export function applyCustomFilterValueFormatter({
    fieldMetadata,
    rowData,
    viewdata,
    metadata,
    returnRawCalculatedValue = false,
}: {
    fieldMetadata: MetadataFieldDefinition;
    rowData: DataItem;
    returnRawCalculatedValue?: boolean;
} & SingleScreenerData) {
    switch (fieldMetadata.value_formatter) {
        case CustomValueFormatterTypes.IsUserEnabled:
            const sourceFields = fieldMetadata?.source_fields || [];

            const rawValue = isUserEnabledFormatter(sourceFields, {
                rowData,
                viewdata,
                metadata,
            });

            if (returnRawCalculatedValue) {
                return rawValue;
            }

            return getBooleanStringValue(rawValue);
        case CustomValueFormatterTypes.Format_Yes_No:
            let result: boolean = false;

            let field = '';
            if (fieldMetadata && fieldMetadata.source_fields && fieldMetadata.source_fields.length > 0) {
                field = fieldMetadata.source_fields[0];
                field = field.slice(field.lastIndexOf('.') + 1);
                result = rowData[field];
                if (result === null) result = false;
            }

            if (returnRawCalculatedValue) {
                return result;
            }
            return getBooleanStringValue(result);

        default:
            throw new Error(
                `applyCustomFilterValueFormatter exception. Unknown custom value formatter: ${fieldMetadata.value_formatter}`,
            );
    }
}

export function applyDisplayFormatter({
    fieldMetadata,
    rowData,
    viewdata,
    metadata,
}: {
    fieldMetadata: MetadataFieldDefinition;
    rowData: DataItem;
} & SingleScreenerData) {
    switch (fieldMetadata.display_formatter) {
        case DisplayFormatterTypes.EnableOnlyWhenTrue:
            let field = '';
            let result: boolean = false;
            if (fieldMetadata && fieldMetadata.source_fields && fieldMetadata.source_fields.length > 0) {
                field = fieldMetadata.source_fields[0];
                field = field.slice(field.lastIndexOf('.') + 1);
                result = rowData[field];
                if (result === null) result = false;
            }
            return !result;
        default:
            return false;
    }
}

export function getFieldMetaBySourceField(fieldMetadata: Metadata, sourceField: string[] | string) {
    const sourceFields = !Array.isArray(sourceField) ? [sourceField] : sourceField;

    return fieldMetadata.fields.filter((fieldMeta) => {
        const metaValue = Object.values(fieldMeta)[0];
        return sourceFields.includes(`${metaValue?.schema_name}.${metaValue?.source_field}`);
    });
}

export function getFieldValueBySourceField({
    rowData,
    metadata,
    viewdata,
    sourceField,
}: {
    sourceField: string;
} & SingleScreenerData) {
    const fieldMeta = metadata.fields.find((fieldMeta) => {
        const metaValue = Object.values(fieldMeta)[0];
        return `${metaValue.schema_name}.${metaValue.source_field}` === sourceField;
    });

    if (fieldMeta) {
        const fieldPath = Object.keys(fieldMeta)[0];
        return getValueByFieldKey({
            rowData,
            viewdata,
            fieldPath,
        });
    }
}

export type SingleScreenerData = {
    rowData: DataItem;
    metadata: Metadata;
    viewdata: Viewdata;
};

export function getValueByFieldKey({
    rowData,
    viewdata,
    fieldPath,
}: {
    fieldPath: string;
} & Omit<SingleScreenerData, 'metadata'>) {
    const fieldViewData = getFieldViewData(viewdata, fieldPath);
    if (isListField(fieldPath, fieldViewData)) {
        return getValuesByPath(rowData, fieldPath);
    }
    return getValueByPath(rowData, fieldPath, fieldViewData?.[fieldPath]);
}

export function updateMetadataUrlLinkParams(filter_item_metadata: any, singleScreenerData: SingleScreenerData) {
    const item_metadata = _.cloneDeep(filter_item_metadata);
    const params_source_field = item_metadata['url_link']['params_source_field'] || {};
    let paramsData: Record<string, any>[] = [{}];
    Object.keys(params_source_field).forEach((paramKey) => {
        if (params_source_field[paramKey]) {
            const value = getFieldValueBySourceField({
                ...singleScreenerData,
                sourceField: params_source_field[paramKey],
            });
            if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    paramsData[i] = { ...paramsData[i], [paramKey]: v };
                });
            } else {
                paramsData = paramsData.map((obj) => ({ ...obj, [paramKey]: value }));
            }
        }
    });

    item_metadata['url_link']['params'] = paramsData;
    return item_metadata;
}

export function isListField(key: string, fieldViewMeta?: IViewDataFields) {
    if (!fieldViewMeta || fieldViewMeta?.[key]?.valueSelection === 'single') {
        return false;
    }
    if (fieldViewMeta[key].valueSelection === 'multiple') return true;
    
    return fieldViewMeta[key]?.cell_renderer_params?.map((param) => param.component === 'list').some((value) => value);
}

export function getFieldViewData(viewData: Viewdata, key: string) {
    return viewData.fields.find((field) => {
        return Object.keys(field)[0] === key;
    });
}

export const validatorNameToFunc: Record<string, Function> = {
    email_validator: (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    },
};

type CommonProcessFileProps = {
    showValidationError: (message: string) => void;
    validationConfig?: Array<SingleColumnValidationConfig>;
    setData: (data: Array<Record<string, string>>) => void;
};

export function extractAndValidateFileData({
    data,
    showValidationError,
    validationConfig,
    setData,
}: {
    data: Array<Record<string, string>>;
} & CommonProcessFileProps): void {
    const validationErrors: Array<string> = [];

    if (data.length === 0) {
        validationErrors.push('No data found in the file.');
        return;
    }

    if (!validationConfig) {
        validationErrors.push('No validation configuration provided');
        return;
    }

    // remove leading and trailing spaces from column names
    const headerRow = data[0];
    Object.keys(headerRow).forEach((key) => {
        headerRow[key] = headerRow[key].trim();
    });

    const headerColumns = Object.keys(headerRow);

    // check for missing columns
    if (headerColumns.length < validationConfig.length) {
        const missingColumns = validationConfig
            .filter((item) => Object.values(headerRow).indexOf(item.columnName) < 0)
            .map((item) => item.columnName)
            .map((item) => quote(item));
        validationErrors.push(
            `Header row length does not match the expected number of columns. Missing columns: ${missingColumns.join(
                ', ',
            )}`,
        );
    }

    // check for extra columns
    if (headerColumns.length > validationConfig.length) {
        const extraColumns = headerColumns.slice(validationConfig.length).map((item) => quote(item));
        validationErrors.push(
            `Expected ${
                validationConfig.length
            } columns in the file, but got more. Unnecessary extra columns: ${extraColumns.join(', ')}`,
        );
    }

    // check if all columns placed on the right positions
    validationConfig
        .map((item) => item.columnName)
        .forEach((name, index) => {
            if (Object.values(headerRow)[index] !== name) {
                validationErrors.push(`Column "${name}" is expected at position ${index + 1}`);
            }
        });

    // don't validate rows, if has errors in header
    if (validationErrors.length > 0) {
        showValidationError(joinWithHtmlBr(validationErrors));
        return;
    }

    // filter rows with all empty values
    const dataRows = data.slice(1).filter((row) => Object.values(row).some((item) => Boolean(item)));

    // validate columns data
    dataRows.forEach((row, rowIndex) => {
        const rowValues = Object.values(row);

        validationConfig.forEach(({ columnName, required, validators }, index) => {
            // count header row, and numerate from 1, not from 0
            const presentableRowNumber = rowIndex + 2;

            if (index > rowValues.length - 1) {
                validationErrors.push(`Row ${presentableRowNumber}: ${columnName} is missing`);
                return;
            }

            if (required && !rowValues[index]) {
                validationErrors.push(`Row ${presentableRowNumber}: ${columnName} is not populated`);
                return;
            }

            validators?.forEach((validator) => {
                // just in case if it will be used out of FilePicker check for validator existence
                if (Object.keys(validatorNameToFunc).indexOf(validator) < 0) {
                    validationErrors.push(`Row ${presentableRowNumber}: Validator not found: ${validator}`);
                }

                if (!validatorNameToFunc[validator as keyof typeof validatorNameToFunc](rowValues[index])) {
                    validationErrors.push(`Row ${presentableRowNumber}: Failed validation for ${columnName}`);
                }
            });
        });
    });

    if (validationErrors.length > 0) {
        showValidationError(joinWithHtmlBr(validationErrors));
        return;
    }

    setData([headerRow, ...dataRows]);
}

function validateGeneralError(file: File, supportedFileExtensions: Array<string>): { isError: boolean, errorText: string } {
    const fileNameWithExtension = file?.name;
    let isError = false, errorText = '';
    if (!fileNameWithExtension) {
        errorText = 'Cant retrieve file name. Please upload a valid file.';
        isError = true;
    }
    const fileNameWithExtensionArray = fileNameWithExtension.split('.');
    if (fileNameWithExtensionArray.length < 2) {
        errorText = 'Invalid file name. Either file name or extension is missing. Please upload a valid file.';
        isError = true;
    }

    const fileExtension = fileNameWithExtensionArray.pop()?.toLowerCase();

    if (!fileExtension || !supportedFileExtensions.includes(fileExtension)) {
        errorText = 
            `Invalid file type. Please upload an file with one of the following extensions: ${supportedFileExtensions.join(
                ', ',
            )}.`
        ;
        isError = true;
    }

    return { isError, errorText };
}

export function processFile({
    file,
    showValidationError,
    supportedFileExtensions,
    ...rest
}: { file: File; supportedFileExtensions: Array<string> } & CommonProcessFileProps): void {
    
    const { isError, errorText } = validateGeneralError(file, supportedFileExtensions)
    if (isError) {
        showValidationError(errorText);
        return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
        const workbook = new Workbook();
        const buffer = e.target?.result;

        if (!buffer || !(buffer instanceof ArrayBuffer)) {
            throw new Error('Error reading the Excel file.');
        }

        await workbook.xlsx.load(buffer);

        // get the first worksheet
        const worksheet = workbook.getWorksheet(1);

        const jsonData: Array<Record<string, string>> = [];
        worksheet?.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            const rowData: Record<string, string> = {};
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                try {
                    rowData[`Column${colNumber}`] = cell.text;
                } catch (e) {
                    showValidationError('Invalid file uploaded. Error reading cell text of one of cells.');
                }
            });
            jsonData.push(rowData);
        });

        extractAndValidateFileData({ data: jsonData, showValidationError, ...rest });
    };
    reader.onerror = (e) => {
        showValidationError('Error reading the file.');
    };

    reader.readAsArrayBuffer(file);
}


export function processWatchlistCSVFile({
    file,
    showValidationError,
    supportedFileExtensions,
    ...rest
}: { file: File; supportedFileExtensions: Array<string> } & CommonProcessFileProps): void {
    
    const { isError, errorText } = validateGeneralError(file, supportedFileExtensions)
    if (isError) {
        showValidationError(errorText);
        return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
        const text = e.target?.result;

        if (!text) {
            throw new Error('Error reading the CSV file.');
        }
        
        const data = csvToArray(text.toString());
        rest.setData(data);

    };

    reader.onerror = (e) => {
        showValidationError('Error reading the file.');
    };

    reader.readAsText(file);

}

function csvToArray(str: string, delimiter = ",") {
    str = str.replaceAll('\r\n', '\n').replaceAll('\r', '\n');

    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
  
    const arr = rows.map((row) => {
      const values = row.split(delimiter);

      if (values && values.length > 0 && values.some(p => p)) {
        const el = headers.reduce(function(obj: any, header, index) {
            obj[header.replaceAll(' ', '_')] = values[index];
            return obj;
        }, {});
        return el;
       }
    });
  
    return arr.filter(p => p);
}
