import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { styled, Typography } from '@mui/material';
import { TypographyStyle11 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Link } from 'react-router-dom';

export const StyledTitle = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '22px',
    color: '#002B5A',
}));

export const StyledLink = styled(Link)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#007AB9',
}));

export const ResearchDescriptionText = styled(TypographyStyle11)(() => ({
    lineHeight: '22px',
}));
