import { ReactNode, createContext, useMemo } from 'react';
import { ProjectSpecificResourcesContextProps } from './types';

export const ProjectSpecificResourcesContext = createContext<ProjectSpecificResourcesContextProps>(
    {} as ProjectSpecificResourcesContextProps,
);

export function ProjectSpecificResourcesContextProvider({
    children,
    projectSpecificResources,
}: {
    children: ReactNode;
    projectSpecificResources: ProjectSpecificResourcesContextProps;
}) {
    const memorizedChildren = useMemo(() => children, [children]);
    return (
        <ProjectSpecificResourcesContext.Provider value={projectSpecificResources}>
            {memorizedChildren}
        </ProjectSpecificResourcesContext.Provider>
    );
}
