import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, SxProps, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { fontFamilies } from '../../utils/fonts';
import { PopoverOrigin } from '@mui/material';

export type MenuItemProps = {
    itemName: string | JSX.Element;
    callback?: (event: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
};

type ETFMenuProps = {
    children: React.ReactNode;
    menuItems: Array<MenuItemProps>;
    menuStyles?: SxProps;
    menuItemStyles?: SxProps;
    paperProps?: SxProps;
    selectedItemIndex?: number;
    onHoverItemStyles?: SxProps;
    addBorderBetweenItems?: boolean;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    keepOpen?: boolean;
};

export function ETFMenu(props: ETFMenuProps) {
    const {
        children,
        menuItems,
        menuStyles = {},
        menuItemStyles = {},
        paperProps = {},
        selectedItemIndex,
        onHoverItemStyles,
        addBorderBetweenItems = true,
        transformOrigin,
        anchorOrigin,
        keepOpen = false,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    let theme = createTheme();

    theme = createTheme(theme, {
        components: {
            MuiMenu: {
                styleOverrides: {
                    root: {
                        zIndex: 3000,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                        marginTop: 5.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            zIndex: 0,
                        },
                        '.MuiPopover-paper': {
                            ...paperProps,
                        },
                        ul: {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            ...menuStyles,
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: fontFamilies.GraphikRegular,
                        fontSize: '14px',
                        lineHeight: 1,
                        color: '#57626a',
                        padding: '12px 20px 12px 24px',
                        borderLeft: 'solid #fff 4px',
                        '&:hover': {
                            background: '#FFF',
                            color: '#007BB8',
                            borderLeft: 'solid #007BBB 4px',
                            ...onHoverItemStyles,
                        },
                        ...(addBorderBetweenItems
                            ? {
                                  ':not(:last-child)': {
                                      borderBottom: 'solid #4375BB 1px',
                                  },
                              }
                            : {}),
                        ...menuItemStyles,
                    },
                },
            },
        },
    });

    const itemSelectedStyles: SxProps = {
        background: '#FFF !important',
        color: '#007BB8',
        borderLeft: 'solid #007BBB 4px !important',
    };

    return (
        <>
            {React.Children.toArray(children).map((child, index) => {
                if (React.isValidElement(child)) {
                    const childWithOnclick = child as React.ReactElement<
                        JSX.Element['type'],
                        JSX.Element['props'] & { onClick: (event: React.MouseEvent<HTMLElement>) => void }
                    >;
                    return React.cloneElement(childWithOnclick, { onClick: handleClick });
                }
                return null;
            })}
            <ThemeProvider theme={theme}>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={open}
                    onClose={handleClose}
                    onClick={keepOpen ? () => {} : handleClose}
                    transformOrigin={transformOrigin || { horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
                    transitionDuration={{ enter: 0, exit: 75 }}
                    PaperProps={{
                        elevation: 0,
                    }}>
                    {menuItems.map(({ itemName, callback = () => {}, disabled = false }, index) => {
                        return (
                            <MenuItem
                                onClick={callback}
                                key={index}
                                className={'data-cy-etf-menu-option'}
                                selected={index === selectedItemIndex}
                                sx={index === selectedItemIndex ? itemSelectedStyles : {}}
                                disabled={disabled}>
                                {itemName}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </ThemeProvider>
        </>
    );
}
