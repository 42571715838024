import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';

export const getSavedItemsByTypeConfig: Parameters<SendSingleRequest>[1] = {
    apiName: ApiNames.UserManagement,
    path: 'saved-items',
    queryKeyFirstElement: 'getSavedItemsByType',
    requestType: RequestTypes.GET,
};

export const updateSavedItemByIdConfig: Parameters<SendSingleRequest>[1] = {
    apiName: ApiNames.UserManagement,
    queryKeyFirstElement: 'updateSavedItemById',
    requestType: RequestTypes.PUT,
    invalidateQuery: true
};

export const deleteSavedItemByIdConfig: Parameters<SendSingleRequest>[1] = {
    apiName: ApiNames.UserManagement,
    queryKeyFirstElement: 'deleteSavedItemById',
    requestType: RequestTypes.DELETE
};

export const createSavedItemConfig: Parameters<SendSingleRequest>[1] = {
    apiName: ApiNames.UserManagement,
    queryKeyFirstElement: 'createSavedItem',
    requestType: RequestTypes.POST,
    path: 'saved-items'
};

export const getSavedItemsByIdConfig: Parameters<SendSingleRequest>[1] = {
    apiName: ApiNames.UserManagement,
    queryKeyFirstElement: 'getSavedItemsById',
    requestType: RequestTypes.GET,
};
