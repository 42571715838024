import { MaterialIcons } from '@cfra-nextgen-frontend/shared/src';
import PlusIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PlusIcon.svg';
import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { CFRAMuiIconWrapper } from '@cfra-nextgen-frontend/shared/src/components/Icon/SvgIcon';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { UserSavedItemsLookupID, fontFamilies, sortByDateString } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';
import { useContext, useState } from 'react';
import { MenuMessageStyles, ScreenerMenu, ScreenerMenuItem } from './BaseScreenerMenu';
import { DeleteSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DeleteSavedItemModal';
import { RenameSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/RenameSavedItemModal';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';

type SavedScreensMenuProps = {
    hideButtonText?: boolean;
};

export function SavedScreenMenu({ hideButtonText }: SavedScreensMenuProps) {
    const cardName = 'my screen menu';
    const {
        chipStateManager: { chipState },
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const {
        saveScreenState: { selectedScreen },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);
    const { watchListDispatcher } = useContext(WatchListContext);
    const { setSearchTerm } = useContext(FiltersModalContext);

    const [deleteScreen, setDeleteScreen] = useState<SavedItemTypes>();
    const [renameScreen, setRenameScreen] = useState<SavedItemTypes>();

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getSavedItemsQuery = sendSingleRequest<SavedItemListResponseTypes>({ types: [UserSavedItemsLookupID.ETFScreenerSavedScreen] }, getSavedItemsByTypeConfig);

    const savedScreenerItems: SavedItemTypes[] = getSavedItemsQuery.data?.data || [];
    const sortedSavedScreens = savedScreenerItems.sort(sortByDateString('updated_date', 'desc'));
    const showChips = chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0;
    const disableSaveScreenBtn = typeof selectedScreen?.id !== 'number' && !showChips;
    const selectedItemIndex = sortedSavedScreens.findIndex((e) => e.id === selectedScreen?.id);
    const disableMyScreenBtn = !getSavedItemsQuery?.data?.data?.length && getSavedItemsQuery.isLoading;

    const myScreenMenuItems = [
        ...sortedSavedScreens.map((screen) => ({
            itemName: (
                <ScreenerMenuItem
                    label={screen.name}
                    id={screen.id}
                    onDeleteClick={handleDeleteClick}
                    onItemClick={handleOnItemClick}
                    onEditClick={handleOnEditClick}
                />
            ),
            callback: () => {},
        })),
        {
            itemName: <SaveScreenMenuItemButton cardName={cardName} />,
            disabled: disableSaveScreenBtn,
            callback: () => {},
        },
    ];

    function handleOnEditClick(id: number) {
        setRenameScreen(sortedSavedScreens.find((e) => e.id === id));
    }

    function handleOnItemClick(id: number) {
        onChipClearAllClick();
        watchListDispatcher({ type: 'ClearSelectedWatchlist' });
        setSearchTerm('');
        const selectedItem = sortedSavedScreens.find((e) => e.id === id);
        saveScreenActionDispatcher({ type: 'SetSelectedScreen', payload: selectedItem });
    }

    function handleDeleteClick(id: number) {
        const deleteItem = sortedSavedScreens.find((e) => e.id === id);
        setDeleteScreen(deleteItem);
    }

    function handleDeleteCancelClick() {
        setDeleteScreen(undefined);
    }

    function handleDeleteConfirmClick() {
        refetchAllSavedScreens();
    }

    function resetSelectedScreen() {
        onChipClearAllClick();
        saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });
    }

    async function refetchAllSavedScreens() {
        const { data, isFetching, isError } = await getSavedItemsQuery.refetch();
        if (!isFetching && !isError) {
            if (selectedScreen?.id && selectedScreen?.id === deleteScreen?.id) {
                resetSelectedScreen();
            }
            if (selectedScreen?.id && selectedScreen?.id === renameScreen?.id) {
                const savedScreenerItems: SavedItemTypes[] = data?.data || [];
                const selectedItem = savedScreenerItems.find((e) => e.id === renameScreen?.id);
                saveScreenActionDispatcher({ type: 'RenameSelectedScreen', payload: selectedItem?.name });
            }
            setDeleteScreen(undefined);
            setRenameScreen(undefined);
        }
    }

    return (
        <>
            <ScreenerMenu menuItems={myScreenMenuItems} selectedItemIndex={selectedItemIndex}>
                <TransparentButtonChip
                    disabled={disableMyScreenBtn}
                    key='My Screens'
                    text={hideButtonText ? '' : 'My Screens'}
                    disableiconrightmargin={hideButtonText ? 'true' : undefined} // avoid Warning: Received `false` for a non-boolean attribute `disableiconrightmargin`.
                    sx={{
                        fontFamily: fontFamilies.GraphikMedium,
                        minWidth: hideButtonText ? 'unset' : '116px', // prevent icon and text from wrapping
                    }}
                    startIcon={<CFRAMuiIconWrapper MuiIcon={MaterialIcons.Bookmark} />}
                />
            </ScreenerMenu>
            {typeof deleteScreen?.id === 'number' && (
                <DeleteSavedItemModal
                    id={deleteScreen.id}
                    name={deleteScreen.name}
                    onCancel={handleDeleteCancelClick}
                    onConfirm={handleDeleteConfirmClick}
                />
            )}
            {typeof renameScreen?.id === 'number' && (
                <RenameSavedItemModal
                    id={renameScreen.id}
                    name={renameScreen.name}
                    savedItemType={UserSavedItemsLookupID.ETFScreenerSavedScreen}
                    modalTitle='Rename Screen'
                    onCancel={() => setRenameScreen(undefined)}
                    onConfirm={() => refetchAllSavedScreens()}
                />
            )}
        </>
    );
}

const SaveScreenMenuItemButton = ({ cardName }: { cardName: string }) => {
    const ButtonText = 'Save New Screen';
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);

    const SaveScreenButton = styled(LinkButton)(({ theme }) => ({
        ...MenuMessageStyles,
        paddingBottom: '7px',
        paddingLeft: '21px',
    }));

    const handleOnClick = () => {
        globalThis.analytics?.registerAction?.({
            action: `click on ${ButtonText}`,
            cardName: cardName,
        });
        saveScreenActionDispatcher({ type: 'OpenSaveScreenModal' });
    };

    return (
        <SaveScreenButton onClick={handleOnClick}>
            <Box component='img' src={PlusIcon} />
            <Box sx={{ color: '#3078B5', pt: '1px', lineHeight: '21px' }}>{ButtonText}</Box>
        </SaveScreenButton>
    );
};
