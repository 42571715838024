import { useContext, useState } from 'react';
import { ProjectSpecificResourcesContext } from '../ProjectSpecificResourcesContext/Context';
import { deleteSavedItemByIdConfig } from '../../utils/userSavedItem';
import { DeleteModal } from './DeleteModal';

type DeleteSavedItemModalProps = {
    id: number;
    name: string;
    onCancel: () => void;
    onConfirm: () => void;
};

export function DeleteSavedItemModal(props: DeleteSavedItemModalProps) {
    const { id, name, onCancel = () => {}, onConfirm = () => {} } = props;
    const [confirm, setConfirm] = useState(false);
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const { isLoading, data } = sendSingleRequest<any>(
        {
            savedItems: id,
            config: {
                enabled: confirm,
            },
        },
        deleteSavedItemByIdConfig,
    );

    if (confirm && !isLoading && !data?.isErroredResponse) {
        onConfirm();
    } else {
        //onCancel(); //TODO: Toastr Message
    }

    return (
        <DeleteModal
            id={id}
            title={`Delete ${name}?`}
            onCancel={onCancel}
            onConfirm={() => {
                setConfirm(true);
            }}
        />
    );
}
