import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import SvgIcon, { SvgIconTypeMap } from '@mui/material/SvgIcon';

export function CFRAMuiIconWrapper({
    MuiIcon,
    theme,
    customIconTheme,
}: {
    MuiIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    theme?: Theme;
    customIconTheme?: Theme;
}) {
    let iconTheme = createTheme(
        {
            components: {
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            color: '#3078B5',
                            fontSize: '15px',
                        },
                    },
                },
            },
        },
        theme || {},
    );

    if (customIconTheme) {
        iconTheme = customIconTheme;
    }

    return (
        <ThemeProvider theme={iconTheme}>
            <SvgIcon viewBox='0 0 40 40'>
                <MuiIcon />
            </SvgIcon>
        </ThemeProvider>
    );
}
