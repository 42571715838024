import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import {
    WatchListRow,
    InstitutionalWatchListRow,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { fontFamilies, fontWeights, sortByDateString } from '@cfra-nextgen-frontend/shared/src/utils';
import { createTheme } from '@mui/material/styles';

export const ModelBoxStyle = {
    padding: '32px 30px',
    maxWidth: '480px',
    height: 'unset',
};

export const Theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...inputFontStyle,
                    height: '36px',
                    padding: '3.5px 5px 3.5px !important',
                },
                input: {
                    fontSize: '16px',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontWeight: fontWeights.Regular,
                    padding: '0px 8px !important',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                    boxShadow: 'none',
                },
                listbox: {
                    maxHeight: '150px',
                    '::-webkit-scrollbar': {
                        width: '20px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#d1d8e8',
                    },
                    '::-webkit-scrollbar-track-piece': {
                        backgroundColor: '#f2f5fc',
                    },
                },
                option: {
                    ':hover': {
                        backgroundColor: '#f7f6f7',
                    },
                },
            },
        },
    },
});

export const roundedTextButtonTheme = createTheme(roundedTextButtonThemeV2, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                },
            },
        },
    },
});

export function getSavedWatchLists(getAllWatchLists: any) {
    return (
        getAllWatchLists.data?.data
            ?.sort(sortByDateString('updated_date', 'desc'))
            .map((watchList: any) => ({ name: watchList.name, id: watchList.id })) || []
    );
}

export function getWatchListData(rows: Array<any>) {
    return rows.map(
        (row: any) =>
            ({
                id: row.id,
                composite_name: row.fund_profile.composite_name,
                composite_ticker: row.fund_profile.composite_ticker,
                type: 'ETF',
            } as WatchListRow),
    );
}
