import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';

export function getExcelNumberFormat(fieldMetadata: MetadataFieldDefinition): string | undefined {
    let format = getFormat(fieldMetadata.format || '');

    if (fieldMetadata.format && !format) {
        return undefined; // assume this case as unexpected, so no value should be returned
    }

    let formatEnding = '';

    switch (fieldMetadata.scale) {
        case 'h':
            if (!fieldMetadata.value_template && fieldMetadata.symbol === '%') {
                formatEnding = '%';
            }
            break;
        case 'K':
            formatEnding = ',';
            break;
        case 'M':
            formatEnding = ',,';
            break;
        case 'B':
            formatEnding = ',,,';
            break;
    }

    if (fieldMetadata.scale && !formatEnding) {
        return undefined; // assume this case as unexpected, so no value should be returned
    }

    if (!fieldMetadata.scale && !fieldMetadata.value_template && fieldMetadata.symbol === '%') {
        formatEnding = '"%"';
    }

    const separateNumberWithCommas = '#,##';

    return `${separateNumberWithCommas}${format}${formatEnding}`;
}

function getFormat(format: string) {
    if (format.includes('#')) {
        return '0';
    } else if (format.includes('.') && format.split('.').length === 2) {
        return format;
    }
    return '';
}

export function getExcelExportDateFormat(format: string): string {
    const formatMap = {
        'M/DD/YYYY hh:mm A': 'm/dd/yyyy hh:mm AM/PM',
        'MM/DD/YYYY hh:mm A': 'mm/dd/yyyy hh:mm AM/PM',
        'MM-DD-YYYY': 'mm-dd-yyyy',
        'MM-DD-YYYY HH:mm:ss': 'MM-DD-YYYY HH:mm:ss',
        'MM/DD/YYYY': 'mm/dd/yyyy',
        'MMM D, YYYY': 'mmm d, yyyy',
        'MMM DD, YYYY': 'mmm dd, yyyy',
        'MMMM YYYY': 'mmmm yyyy',
    };

    if (!Object.keys(formatMap).includes(format)) {
        throw new Error(`getExcelExportDateFormat received format out of expected range - "${format}".`);
    }

    return formatMap[format as keyof typeof formatMap];
}
